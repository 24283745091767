define("ember-can/initializers/setup-ember-can", ["exports", "ember-resolver"], function (_exports, _emberResolver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  _emberResolver.default.reopen({
    init() {
      this._super(...arguments);

      this.set('pluralizedTypes.ability', 'abilities');
    }

  });

  function initialize() {}

  var _default = {
    initialize
  };
  _exports.default = _default;
});
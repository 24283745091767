define("ember-composable-helpers/helpers/invoke", ["exports", "@ember/array", "@ember/component/helper", "rsvp"], function (_exports, _array, _helper, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.invoke = invoke;
  const {
    all
  } = _rsvp.default;

  function invoke(_ref) {
    let [methodName, ...args] = _ref;
    let obj = args.pop();

    if ((0, _array.isArray)(obj)) {
      return function () {
        let promises = obj.map(item => item[methodName]?.(...args));
        return all(promises);
      };
    }

    return function () {
      return obj[methodName]?.(...args);
    };
  }

  var _default = (0, _helper.helper)(invoke);

  _exports.default = _default;
});
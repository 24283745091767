define("ember-can/helpers/can", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@ember/component/helper", "@ember/service"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  let CanHelper = (_dec = (0, _service.inject)('abilities'), (_class = class CanHelper extends _helper.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "abilities", _descriptor, this);
    }

    compute(_ref) {
      let [abilityString, model] = _ref;
      let properties = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      return this.abilities.can(abilityString, model, properties);
    }

  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "abilities", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CanHelper;
});
define("tracked-maps-and-sets/-private/map", ["exports", "ember-tracked-storage-polyfill"], function (_exports, _emberTrackedStoragePolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TrackedWeakMap = _exports.TrackedMap = void 0;

  class TrackedMap {
    constructor(existing) {
      this.collection = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
      this.storages = new Map(); // TypeScript doesn't correctly resolve the overloads for calling the `Map`
      // constructor for the no-value constructor. This resolves that.

      this.vals = existing ? new Map(existing) : new Map();
    }

    readStorageFor(key) {
      const {
        storages
      } = this;
      let storage = storages.get(key);

      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }

      (0, _emberTrackedStoragePolyfill.getValue)(storage);
    }

    dirtyStorageFor(key) {
      const storage = this.storages.get(key);

      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    } // **** KEY GETTERS ****


    get(key) {
      // entangle the storage for the key
      this.readStorageFor(key);
      return this.vals.get(key);
    }

    has(key) {
      this.readStorageFor(key);
      return this.vals.has(key);
    } // **** ALL GETTERS ****


    entries() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.entries();
    }

    keys() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.keys();
    }

    values() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.values();
    }

    forEach(fn) {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      this.vals.forEach(fn);
    }

    get size() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals.size;
    }

    [Symbol.iterator]() {
      (0, _emberTrackedStoragePolyfill.getValue)(this.collection);
      return this.vals[Symbol.iterator]();
    }

    get [Symbol.toStringTag]() {
      return this.vals[Symbol.toStringTag];
    } // **** KEY SETTERS ****


    set(key, value) {
      this.dirtyStorageFor(key);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.set(key, value);
      return this;
    }

    delete(key) {
      this.dirtyStorageFor(key);
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      return this.vals.delete(key);
    } // **** ALL SETTERS ****


    clear() {
      this.storages.forEach(s => (0, _emberTrackedStoragePolyfill.setValue)(s, null));
      (0, _emberTrackedStoragePolyfill.setValue)(this.collection, null);
      this.vals.clear();
    }

  } // So instanceof works


  _exports.TrackedMap = TrackedMap;
  Object.setPrototypeOf(TrackedMap.prototype, Map.prototype);

  class TrackedWeakMap {
    constructor(existing) {
      this.storages = new WeakMap(); // TypeScript doesn't correctly resolve the overloads for calling the `Map`
      // constructor for the no-value constructor. This resolves that.

      this.vals = existing ? new WeakMap(existing) : new WeakMap();
    }

    readStorageFor(key) {
      const {
        storages
      } = this;
      let storage = storages.get(key);

      if (storage === undefined) {
        storage = (0, _emberTrackedStoragePolyfill.createStorage)(null, () => false);
        storages.set(key, storage);
      }

      (0, _emberTrackedStoragePolyfill.getValue)(storage);
    }

    dirtyStorageFor(key) {
      const storage = this.storages.get(key);

      if (storage) {
        (0, _emberTrackedStoragePolyfill.setValue)(storage, null);
      }
    }

    get(key) {
      this.readStorageFor(key);
      return this.vals.get(key);
    }

    has(key) {
      this.readStorageFor(key);
      return this.vals.has(key);
    }

    set(key, value) {
      this.dirtyStorageFor(key);
      this.vals.set(key, value);
      return this;
    }

    delete(key) {
      this.dirtyStorageFor(key);
      return this.vals.delete(key);
    }

    get [Symbol.toStringTag]() {
      return this.vals[Symbol.toStringTag];
    }

  } // So instanceof works


  _exports.TrackedWeakMap = TrackedWeakMap;
  Object.setPrototypeOf(TrackedWeakMap.prototype, WeakMap.prototype);
});
define("ember-a11y-refocus/utils/validators", ["exports", "ember-a11y-refocus/utils/routing"], function (_exports, _routing) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultValidator = defaultValidator;

  function defaultValidator(transition) {
    return !(0, _routing.routeInfoEqual)(transition.from, transition.to);
  }
});
define("ember-simple-auth/utils/assign", ["exports", "@ember/polyfills"], function (_exports, _polyfills) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Object.assign || _polyfills.assign;

  _exports.default = _default;
});